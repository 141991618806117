<template>
    <div style="width: 320px">
        <LogTable :entityId="'632'"
                  :entityType="6"
                  :type="4"></LogTable>
    </div>
</template>

<script>
    import LogTable from '@/components/LogTable';

    export default {
        components: {
            LogTable
        }
    };
</script>